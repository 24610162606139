import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';

import client from '../../modules/client'
import Loading from '../../components/Loading';

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    width: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#fff',
    padding: '60px 12px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 1.5
  },
  inputWrapper: {
    marginTop: 30,
    width: '100%',
    position: 'relative',
  },
  inputLabel: {
    marginBottom: 10,
    fontSize: '18px',
    fontWeight: 'bold',
  },
  inputInfo: {
    marginTop: 10
  },
  input: {
    width: '100%',
    height: '36px',
    fontSize: '18px',
    padding: '6px',
    border: '1px solid #999',
    borderRadius: '2px'
  },
  select: {
    width: "100%",
    border: "1px solid #999",
    height: "36px",
    fontSize: "18px",
    padding: "6px",
    borderRadius: "2px",
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 48
  },
  errorMsg: {
    marginTop: 20,
    fontSize: '18px',
    textAlign: "center",
    color: "#ed4337"
  },
})

function SubmitAnswer() {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const referrerId = useSelector(state => state.referrer.id)
  const referrerTestId = useSelector(state => state.referrer.testId)
  const user = useSelector(state => state.user.data)
  const answers = useSelector(state => state.answers)
  const [name, setName] = useState('')
  const [role, setRole] = useState('')
  const [email, setEmail] = useState(user.email)
  const [error, setError] = useState(false)
  const classes = useStyles()

  const handlePopupNameInput = e => {
    setName(e.target.value)
  }

  const handleEmailInput = e => {
    setEmail(e.target.value)
  }

  const handleRoleSelect = e => {
    setRole(e.target.value)
  }

  const handleSubmit = () => {
    setLoading(true)
    const trimmedName = name.trim();
    const trimmedEmail = email.trim();
    if (trimmedName !== '' && role !== '') {
      if (referrerId !== null) {
        client({
          method: 'get',
          url: '/getUserAnswerById',
          params: {
            testId: referrerTestId
          }
        }).then(res => {
          const referrerTestData = res.data;
          client({
            method: 'post',
            url: '/submitMatchAnswer',
            data: {
              referrerTestData: referrerTestData,
              answers: answers,
              user: {
                name: trimmedName,
                role: role,
                email: trimmedEmail,
                id: user.id,
              }
            }
          })
        })
      }
      client({
        method: 'post',
        url: '/submitAnswer',
        data: {
          answers: answers,
          user: {
            name: trimmedName,
            role: role,
            email: trimmedEmail,
            id: user.id,
          }
        }
      }).then(res => {
        setLoading(false)
        history.push(`/share-test?userId=${user.id}&testId=${res.data.id}`)
      })
    } else {
      setLoading(false)
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 2000)
    }
  }

  return loading ? <Loading /> : (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        마지막으로,
      </div>
      <div className={classes.title}>
        레포트에 사용될 이름과 신분
      </div>
      <div className={classes.title}>
        전송받으실 이메일을 적어주세요.
      </div>
      <br />
      <div className={classes.inputWrapper}>
        <div className={classes.inputLabel}>
          이름(최대 4글자)
        </div>
        <input
          className={classes.input}
          type="text"
          value={name}
          onInput={handlePopupNameInput}
          placeholder="이름을 입력해주세요."
        />
      </div>
      <div className={classes.inputWrapper}>
        <div className={classes.inputLabel}>
          신분
        </div>
        <select name="role-select" onChange={handleRoleSelect} value={role} className={classes.select}>
          <option value="" disabled selected>신분을 선택해주세요.</option>
          <option value="학생">학생</option>
          <option value="학부모">학부모</option>
        </select>
      </div>
      <div className={classes.inputWrapper}>
        <div className={classes.inputLabel}>
          이메일
        </div>
        <input
          className={classes.input}
          type="text"
          value={email}
          onInput={handleEmailInput}
          placeholder="이메일을 입력해주세요."
        />
        <div className={classes.inputInfo}>* 변경을 원하시면 새로 입력해주세요.</div>
      </div>
      <div className={classes.btnWrapper}>
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={handleSubmit}
        >
          결과 제출하기
        </Button>
      </div>
      {error ? <div className={classes.errorMsg}>이름과 신분을 모두 입력해주세요.</div> : null}
    </div>
  )
}

export default SubmitAnswer
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import QuestionItem from "../../components/Test/QuestionItem";
import client from "../../modules/client";
import { scrollTop } from "../../helpers";
import Header from "../../components/Header";
import Loading from "../../components/Loading";

import { SET_ANSWER } from "../../store/actionTypes";

const countValues = (obj) => {
  let count = 0;
  Object.values(obj).forEach((element) => {
    if (element.value !== "") {
      count += 1;
    }
  });
  return count;
};

const useStyles = makeStyles({
  wrapper: {
    width: "100%",
    height: "100%",
    position: "relative",
    padding: "16px 16px 110px",
  },
  buttonWrapper: {
    marginTop: "72px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  prevButtonWrapper: {
    marginTop: 18,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  prevBtn: {
    paddingBottom: 40,
  },
  progressBar: {
    position: "fixed",
    bottom: 24,
    left: 0,
    width: "100%",
    height: 3,
    backgroundColor: "#eee",
    marginBottom: 32,
  },
  progressBarFilled: {
    height: 3,
    backgroundColor: "#000",
  },

  uselessDiv: {
    marginTop: 24,
    color: "#666",
    fontSize: 12,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    height: 120,
  },
});

const TakeTest = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [questions, setQuestions] = useState({});
  const [nextAvailable, setNextAvailable] = useState(false);
  const [loading, setLoading] = useState(true);

  const lastPageIndex = Math.floor(Object.keys(questions).length / 12);
  const classes = useStyles();

  useEffect(() => {
    client({
      method: "get",
      url: "/questions",
    }).then((res) => {
      const initialQuestions = res.data.reduce((acc, cur, i) => {
        acc[i] = {
          id: cur.id,
          description: cur.description,
          value: "",
          idx: i,
        };
        return acc;
      }, {});
      setQuestions(initialQuestions);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const length = Object.values(questions).length;
    var empties = length - countValues(questions);
    if (page !== lastPageIndex) {
      if ((length - empties) % 12 !== 0) {
        setNextAvailable(false);
      } else if (empties === length) {
        setNextAvailable(false);
      } else {
        setNextAvailable(true);
      }
    } else {
      if ((length - empties) % 6 !== 0) {
        setNextAvailable(false);
      } else {
        setNextAvailable(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  const handleNext = () => {
    scrollTop();
    setPage(page + 1);
  };

  const goToSubmitAnswer = () => {
    history.push("submit-answer");
  };

  const setAnswer = (data) => {
    const pageviewWrapper = document.getElementsByClassName(
      "pageview-wrapper"
    )[0];
    const currentScrollTop = pageviewWrapper.scrollTop;
    pageviewWrapper.scrollTo({
      top: currentScrollTop + 316,
      left: 0,
      behavior: "smooth",
    });
    dispatch({ type: SET_ANSWER, questionId: data.id, answer: data.value });
    setQuestions({ ...questions, [data.idx]: data });
  };

  const progress = (page / lastPageIndex) * window.innerWidth;

  return loading ? (
    <Loading />
  ) : (
    <div className={classes.wrapper + " taketest-wrapper"}>
      <Header />
      <div className={classes.progressBar}>
        <div
          className={classes.progressBarFilled}
          style={{ width: progress }}
        />
      </div>
      {page !== lastPageIndex ? (
        <div>
          <QuestionItem
            question={questions[page * 12]}
            setAnswer={(data) => setAnswer(data)}
          />
          <QuestionItem
            question={questions[page * 12 + 1]}
            setAnswer={(data) => setAnswer(data)}
          />
          <QuestionItem
            question={questions[page * 12 + 2]}
            setAnswer={(data) => setAnswer(data)}
          />
          <QuestionItem
            question={questions[page * 12 + 3]}
            setAnswer={(data) => setAnswer(data)}
          />
          <QuestionItem
            question={questions[page * 12 + 4]}
            setAnswer={(data) => setAnswer(data)}
          />
          <QuestionItem
            question={questions[page * 12 + 5]}
            setAnswer={(data) => setAnswer(data)}
          />
          <QuestionItem
            question={questions[page * 12 + 6]}
            setAnswer={(data) => setAnswer(data)}
          />
          <QuestionItem
            question={questions[page * 12 + 7]}
            setAnswer={(data) => setAnswer(data)}
          />
          <QuestionItem
            question={questions[page * 12 + 8]}
            setAnswer={(data) => setAnswer(data)}
          />
          <QuestionItem
            question={questions[page * 12 + 9]}
            setAnswer={(data) => setAnswer(data)}
          />
          <QuestionItem
            question={questions[page * 12 + 10]}
            setAnswer={(data) => setAnswer(data)}
          />
          <QuestionItem
            question={questions[page * 12 + 11]}
            setAnswer={(data) => setAnswer(data)}
          />
        </div>
      ) : (
        <div>
          <QuestionItem
            question={questions[page * 12]}
            setAnswer={(data) => setAnswer(data)}
          />
          <QuestionItem
            question={questions[page * 12 + 1]}
            setAnswer={(data) => setAnswer(data)}
          />
          <QuestionItem
            question={questions[page * 12 + 2]}
            setAnswer={(data) => setAnswer(data)}
          />
          <QuestionItem
            question={questions[page * 12 + 3]}
            setAnswer={(data) => setAnswer(data)}
          />
          <QuestionItem
            question={questions[page * 12 + 4]}
            setAnswer={(data) => setAnswer(data)}
          />
          <QuestionItem
            question={questions[page * 12 + 5]}
            setAnswer={(data) => setAnswer(data)}
          />
        </div>
      )}
      <div className={classes.buttonWrapper}>
        {page !== lastPageIndex ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.nextBtn}
            size="large"
            disabled={!nextAvailable}
            fullWidth={true}
            endIcon={<ArrowForwardIcon />}
          >
            다음
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.nextBtn}
            onClick={goToSubmitAnswer}
            size="large"
            fullWidth={true}
            disabled={!nextAvailable}
            endIcon={<ArrowForwardIcon />}
          >
            다음
          </Button>
        )}
      </div>
      <div className={classes.uselessDiv}>
        ※ 직관적인 설문을 위하여 다음을 누르시면 수정할 수 없습니다.
      </div>
    </div>
  );
};

export default TakeTest;
